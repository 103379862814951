import Keyboard from 'simple-keyboard';
import EventManager from './lib/EventManager.js';

import 'simple-keyboard/build/css/index.css';

const layouts = {
    email: {
        default: [
            'q w e r t y u i o p {bksp}',
            'a s d f g h j k l {enter}',
            '{shift} z x c v b n m @ . {shift}',
            '{meta1} {space} _ - {accept}',
        ],
        shift: [
            'Q W E R T Y U I O P {bksp}',
            'A S D F G H J K L {enter}',
            '{shift} Z X C V B N M @ . {shift}',
            '{meta1} {space} _ - {accept}',
        ],
        meta1: [
            '1 2 3 4 5 6 7 8 9 0 {bksp}',
            "` | { } % ^ * / ' {enter}",
            '{meta2} $ & ~ # = + . {meta2}',
            '{default} {space} ! ? {accept}',
        ],
        meta2: [
            '[ ] { } \u2039 \u203a ^ * " , {bksp}',
            '\\ | / < > $ \u00a3 \u00a5 \u2022 {enter}',
            '{meta1} \u20ac & ~ # = + . {meta1}',
            '{default} {space} ! ? {accept}',
        ],
    },
    text: {
        default: [
            'q w e r t y u i o p å {bksp}',
            'a s d f g h j k l ö ä {enter}',
            '{shift} z x c v b n m , . {shift}',
            '{meta1} {space} {meta1} {accept}',
        ],
        shift: [
            'Q W E R T Y U I O P Å {bksp}',
            'A S D F G H J K L Ö Ä {enter}',
            '{shift} Z X C V B N M ! ? {shift}',
            '{meta1} {space} {meta1} {accept}',
        ],
        meta1: [
            '1 2 3 4 5 6 7 8 9 0 {bksp}',
            '- / : ; ( ) \u20ac & @ {enter}',
            '{meta2} . , ? ! \' " {meta2}',
            '{default} {space} {default} {accept}',
        ],
        meta2: [
            '[ ] { } # % ^ * + = {bksp}',
            '_ \\ | ~ < > $ \u00a3 \u00a5 {enter}',
            '{meta1} . , ? ! \' " {meta1}',
            '{default} {space} {default} {accept}',
        ],
    },
};

const VirtualKeyboard = {
    wrapper: document.getElementById('keyboard'),
    eventManager: new EventManager(),
    instance: null,
    setup() {
        this.instance = new Keyboard({
            display: {
                '{space}': '&nbsp;',
                '{shift}': '⇧',
                '{bksp}': '\u2190',
                '{enter}': 'return',
                '{default}': 'ABC',
                '{meta1}': '.?123',
                '{meta2}': '#+=',
                '{accept}': '\u21d3',
            },
            buttonTheme: [
                {
                    class: 'hg-button-space',
                    buttons: '{space}',
                },
            ],
            inputName: 'default',
            preventMouseDownDefault: true,
            onChange: (input) => this.onInput(input),
            onKeyPress: (input) => this.onKeyPress(input),
        });
    },
    show() {
        this.wrapper.classList.remove('hidden');
    },
    hide() {
        this.wrapper.classList.add('hidden');
    },
    set(value) {
        this.instance.setInput(value);

        if (this.instance.options.layoutName === 'shift') {
            this.instance.setOptions({
                layoutName: 'default',
            });
        }
    },
    setLayout(layoutName) {
        const layout = layouts[layoutName];

        this.instance.setOptions({ layout });
    },
    onInput(input) {
        this.eventManager.emit('input', input);

        if (this.instance.options.layoutName === 'shift') {
            this.instance.setOptions({
                layoutName: 'default',
            });
        }
    },
    onKeyPress(input) {
        const layouts = Object.keys(this.instance.options.layout).map(
            (k) => `{${k}}`,
        );

        if (input !== '{shift}' && layouts.includes(input)) {
            this.instance.setOptions({
                layoutName: input.replace('{', '').replace('}', ''),
            });
        } else if (input === '{shift}') {
            this.instance.setOptions({
                layoutName:
                    this.instance.options.layoutName === 'default'
                        ? 'shift'
                        : 'default',
            });
        } else if (input === '{enter}' || input === '{accept}') {
            this.eventManager.emit('enter');
        }
    },
    on(event, cb) {
        this.eventManager.on(event, cb);
    },
};

class Input {
    constructor(input) {
        this.input = input;
        this.isBoolean = ['radio', 'checkbox'].includes(input.type);
        this.hasFocus = false;
        this.error = false;
        this.errorContainer = this.input
            .closest('.form-control')
            .querySelector('.input-error');
        this.helpContainer = this.input
            .closest('.form-control')
            .querySelector('.input-help');
        this.layout = this.input.type === 'email' ? 'email' : 'text';

        if (!this.isBoolean && VirtualKeyboard.instance !== null) {
            this.attach();
        }
    }

    attach() {
        this.input.addEventListener('focus', this.onFocus);
        this.input.addEventListener('blur', this.onBlur);
        this.input.addEventListener('input', this.onInput);

        VirtualKeyboard.on('input', this.onVirtualInput);
        VirtualKeyboard.on('enter', this.onVirtualEnter);
    }

    onFocus = () => {
        this.hasFocus = true;

        VirtualKeyboard.set(this.input.value);
        VirtualKeyboard.setLayout(this.layout);
        VirtualKeyboard.show();

        const autocapitalize = this.input.getAttribute('autocapitalize');

        if (
            autocapitalize &&
            autocapitalize !== 'off' &&
            autocapitalize !== 'none' &&
            !this.input.value.length
        ) {
            VirtualKeyboard.autoCapitalize = autocapitalize;
            VirtualKeyboard.instance.setOptions({
                layoutName: 'shift',
            });
        } else {
            VirtualKeyboard.instance.setOptions({
                layoutName: 'default',
            });
        }
    };

    onBlur = () => {
        this.hasFocus = false;
        VirtualKeyboard.hide();
    };

    onInput = () => {
        VirtualKeyboard.set(this.input.value);
    };

    onVirtualInput = (input) => {
        if (this.hasFocus) {
            this.input.value = input;
        }
    };

    onVirtualEnter = () => {
        if (this.hasFocus) {
            this.input.closest('form').querySelector('button').click();
        }
    };

    setError(error) {
        this.error = error;

        if (this.errorContainer) {
            this.errorContainer.innerHTML = error;
        }

        if (this.helpContainer) {
            this.helpContainer.style.display = 'none';
        }
    }

    clearError() {
        this.error = false;

        if (this.errorContainer) {
            this.errorContainer.innerHTML = '';
        }

        if (this.helpContainer) {
            this.helpContainer.style.display = null;
        }
    }

    hasError() {
        return this.error !== false;
    }
}

export default function createInputs(keyboard = true) {
    const inputs = document.body.querySelectorAll('input');

    if (keyboard) {
        VirtualKeyboard.setup();
    }

    [].forEach.call(inputs, (el) => {
        el.inputManager = new Input(el);
    });
}
