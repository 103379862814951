import { Readability } from '@mozilla/readability';

const modal = document.getElementById('modal');
const modalContent = document.getElementById('modalContent');
const cached = {};

function replaceLink(link) {
    const span = document.createElement('span');

    span.innerHTML = link.textContent;

    link.parentNode.replaceChild(span, link);
}

function cache(key, value) {
    cached[key] = value;

    return value;
}

function fetchCached(url, save) {
    if (url in cached) {
        return Promise.resolve(cached[url]);
    }

    return fetch(url)
        .then((r) => r.json())
        .then(save)
        .then((content) => cache(url, content));
}

function openUrl(url) {
    fetchCached(url, (json) => {
        const documentClone = document.cloneNode(true);

        documentClone.body.innerHTML = json.content.rendered;

        const article = new Readability(documentClone).parse();
        const content = document.createElement('div');

        content.innerHTML = article.content;
        content.querySelectorAll('a').forEach(replaceLink);

        return content.innerHTML;
    }).then(openModal);
}

function openModal(content) {
    modalContent.innerHTML = content;
    modal.classList.remove('hidden');
}

function closeModal() {
    modal.classList.add('hidden');
    modalContent.innerHTML = '';
}

function onClick(e) {
    const close = e.target.closest('[data-modal-close]');

    if (close) {
        closeModal();

        return;
    }

    const link = e.target.closest('a[target="_blank"]');

    if (link) {
        e.preventDefault();

        openUrl(link.href);

        return;
    }

    const modal = e.target.closest('.modal');

    if (!modal) {
        closeModal();
    }
}

export default function setup() {
    document.body.addEventListener('click', onClick);
}
