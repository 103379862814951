import EventManager from './lib/EventManager.js';

let ttl = 100000;
const eventManager = new EventManager();
let timer = null;
let count = 0;
let counter = null;

function dispatch() {
    eventManager.emit('inactive');
}

function countdown() {
    count -= 1;

    if (count <= 0) {
        eventManager.emit('inactivityCountdown', null);
        clearInterval(counter);
        return;
    }

    eventManager.emit('inactivityCountdown', count);
}

function start() {
    timer = setTimeout(dispatch, ttl);
    count = ttl / 1000;
    counter = setInterval(countdown, 1000);
}

function stop() {
    eventManager.emit('inactivityCountdown', null);
    clearTimeout(timer);
    clearInterval(counter);
}

function reset() {
    stop();
    start();
}

function setTtl(time) {
    ttl = time;
}

function onInactive(cb) {
    eventManager.on('inactive', cb);
}

function onCountdown(cb) {
    eventManager.on('inactivityCountdown', cb);
}

function activate() {
    document.body.addEventListener('keydown', reset);
    document.body.addEventListener('touchend', reset);
}

function deactivate() {
    stop();

    document.body.removeEventListener('keydown', reset);
    document.body.removeEventListener('touchend', reset);
}

export default {
    dispatch,
    start,
    stop,
    reset,
    onInactive,
    onCountdown,
    activate,
    deactivate,
    setTtl,
};
