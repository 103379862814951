import Bugsnag from '@bugsnag/js';
import machine from './js/machine.js';
import viewManager from './js/viewManager.js';
import inactivityManager from './js/inactivityManager.js';
import offlineManager from "./js/offlineManager.js";
import api from './js/lib/api.js';
import createInputs from './js/input.js';
import isValidEmail from './js/lib/isValidEmail.js';
import Form from './js/lib/Form.js';
import setupModals from './js/modal.js';
import parseQueryString from './js/lib/parseQueryString.js';

function onInactive() {
    document.location.reload();
}

function onCountdown(timeLeft) {
    if (timeLeft !== null && timeLeft <= 10) {
        viewManager.inactivityBar.classList.remove('hidden');

        let message = `Restarts in ${timeLeft} seconds.`;

        if (!machine.isFinal()) {
            message += ` Touch screen to dismiss.`;
        }

        viewManager.inactivityBar.innerHTML = message;
    } else {
        viewManager.inactivityBar.classList.add('hidden');
    }
}

function handleBlipInput(e) {
    // Exit early if in wrong state to avoid unnecessary work
    if (!machine.can('blip')) {
        return;
    }

    if (e.key === 'Enter' && machine.store.hasValidCardNumber()) {
        machine.dispatch('blip');
    } else if (e.code.includes('Digit')) {
        machine.store.enterCardNumber(e.key);
    }
}

function setupEmailForm() {
    return new Form(
        viewManager.getView('CONNECT'),
        'check-email',
        (data) => ({ ...data, cardnumber: machine.store.cardnumber }),
        (data) => {
            if (!isValidEmail(data.email)) {
                return { email: 'Enter a valid email address.' };
            }

            return true;
        },
        ({ success, user_exists, error_code }, data, form) => {
            if (success && user_exists && !error_code) {
                machine.dispatch('login');
            } else if (success && !user_exists && !error_code) {
                machine.store.email = data.email;
                machine.dispatch('unknownUser');
            } else {
                form.setInputErrors({
                    email: error_code || 'Something went wrong',
                });
            }
        },
        () => machine.can('login'),
    );
}

function setupRegistrationForm() {
    return new Form(
        viewManager.getView('REGISTRATION'),
        'register-user',
        (data) => ({
            ...data,
            cardnumber: machine.store.cardnumber,
            email: machine.store.email,
            city: machine.store.city,
        }),
        (data) => {
            const errors = {};

            if (!data.terms) {
                errors.terms = 'You have to accept the terms and conditions.';
            }

            if (!data['first-name'].length) {
                errors['first-name'] = "First name can't be empty.";
            }

            if (!data['last-name'].length) {
                errors['last-name'] = "Last name can't be empty.";
            }

            if (!data.username.length) {
                errors.username = "Username can't be empty.";
            }

            if (!data.password.length) {
                errors.password = "Password can't be empty.";
            }

            if (Object.keys(errors).length) {
                return errors;
            }

            return true;
        },
        (json, data, el, form) => {
            if (json.success) {
                Bugsnag.leaveBreadcrumb('User registered', {}, 'user');
                machine.dispatch('register');
            } else if (json.code === 'validation' && json.data) {
                form.setInputErrors(json.data.errors);
            } else {
                Bugsnag.notify(new Error(json.message));
                console.error(json.message);
            }
        },
        () => machine.can('register'),
    );
}

/**
 * Idle screen
 *
 * Setup handlers and wait for card blip.
 */
machine.on('IDLE', () => {
    window.onkeydown = handleBlipInput;

    viewManager.getView('REGISTRATION').querySelector('form').reset();
    viewManager.getView('CONNECT').querySelector('form').reset();

    inactivityManager.deactivate();
    offlineManager.start();
});

/**
 * Leaving idle screen
 *
 * Cleanup state
 */
machine.off('IDLE', () => {
    inactivityManager.activate();
    window.onkeydown = null;
});

/**
 * Validate card number
 *
 * Called on enter when number is of valid length.
 * Send to backend for user verification.
 */
machine.on('VALIDATING', () => {
    api(
        `verify/${machine.store.cardnumber}/checkcard/${machine.store.city}`,
    ).then((data) => {
        if (data.count === '0') {
            Bugsnag.leaveBreadcrumb('Card already connected', {}, 'user');
            machine.dispatch('connect');
        } else if (data.message) {
            Bugsnag.notify(new Error(data.message));
            machine.dispatch('reject');
        } else {
            Bugsnag.leaveBreadcrumb('Card submitted', {}, 'user');
            machine.dispatch('accept');
        }
    });
});

machine.on('transition', (to, from) => {
    Bugsnag.leaveBreadcrumb(
        'State change',
        {
            from,
            to,
        },
        'state',
    );

    if (machine.isFinal()) {
        inactivityManager.deactivate();
        inactivityManager.setTtl(5000);
        inactivityManager.reset();
    }
});

window.onload = () => {
    if (process.env.BUGSNAG_KEY) {
        Bugsnag.start(process.env.BUGSNAG_KEY);
    }

    const { city, keyboard } = parseQueryString(window.location.search);

    if (city) {
        machine.store.setCity(city);
    }

    console.log(
        `🎉 Registration screen in ${machine.store.city} is up and running!`,
    );

    viewManager.start();
    machine.start();

    createInputs(keyboard !== '0');
    setupEmailForm();
    setupRegistrationForm();
    setupModals();

    inactivityManager.onInactive(onInactive);
    inactivityManager.onCountdown(onCountdown);

    document.body.addEventListener('contextmenu', (e) => e.preventDefault());
};
