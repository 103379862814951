/**
 * Parse query string
 * @param str
 * @returns {{[p: string]: any}}
 */
export default function parseQueryString(str) {
    return Object.fromEntries(
        str.replace('?', '').split('&').map((s) => s.split('=').map(decodeURIComponent))
    );
}
