import api from './api.js';

export default class Form {
    constructor(el, endpoint, parseData, onValidate, onSuccess, can) {
        this.form = el.querySelector('form');
        this.endpoint = endpoint;
        this.onValidate = onValidate;
        this.onSuccess = onSuccess;
        this.parseData = parseData;
        this.inputs = {};
        this.data = {};
        this.can = can;
        this.error = false;
        this.errorContainer = this.form.querySelector('.error');

        this.form.addEventListener('submit', this.onSubmit);
        this.form.addEventListener('reset', this.clearError);
        this.cache();
    }

    cache() {
        [].forEach.call(this.form.querySelectorAll('input'), (input) => {
            this.inputs[input.name] = input;
        });
    }

    reset() {
        this.data = {};
        this.clearError();
        this.form.submit();
    }

    setError(error) {
        this.error = error;
        this.errorContainer.innerHTML = error;
    }

    setInputErrors(errors) {
        Object.entries(errors).forEach(([name, error]) => {
            if (name in this.inputs) {
                this.inputs[name].inputManager.setError(error);
            }
        });

        const inputs = Object.values(this.inputs).filter((input) =>
            input.inputManager.hasError(),
        );

        if (inputs.length) {
            inputs[0].focus();
        }
    }

    clearError = () => {
        this.error = false;
        this.errorContainer.innerHTML = '';

        Object.entries(this.inputs).forEach(([, { inputManager }]) => {
            inputManager.clearError();
        });
    };

    collect() {
        this.data = {};

        Object.entries(this.inputs).forEach(
            ([name, { type, value, checked }]) => {
                this.data[name] = ['radio', 'checkbox'].includes(type)
                    ? checked
                    : value;
            },
        );

        this.data = this.parseData(this.data);
    }

    validate() {
        const response = this.onValidate(this.data);

        if (response === true) {
            return true;
        }

        this.setInputErrors(response);
    }

    onSubmit = (e) => {
        e.preventDefault();

        if (!this.can()) {
            return;
        }

        // Make sure input blurs when submitting with virtual keyboard
        document.activeElement.blur();

        this.collect();
        this.clearError();

        if (!this.validate()) {
            return;
        }

        api(this.endpoint, 'POST', this.data)
            .then((response) =>
                this.onSuccess(response, this.data, this.form, this),
            )
            .catch((ex) => {
                console.error(ex);
                this.setError('Something went wrong. Please try again');
            });
    };
}
