import machine from './machine.js';

export default {
    container: document.getElementById('viewport'),
    backButton: document.getElementById('back'),
    inactivityBar: document.getElementById('inactivityBar'),
    isTransitioning: false,
    views: {},
    currentView: 'IDLE',
    start() {
        const views = this.container.querySelectorAll('.view');

        [].forEach.call(views, (el, position) => {
            this.views[el.id] = { el, position };
        });

        this.backButton.addEventListener('click', () => this.goBack());

        machine.on('transition', (state) => this.onMachineStateChange(state));
    },
    getView(id) {
        return this.views[id].el;
    },
    getViewPosition(id) {
        return this.views[id].position;
    },
    transitionTo(view) {
        const currentView = this.getView(this.currentView);
        const nextView = this.getView(view);
        const position = this.getViewPosition(view);

        if (nextView === currentView) {
            return;
        }

        if (machine.can('back')) {
            this.backButton.classList.remove('hidden');
        } else {
            this.backButton.classList.add('hidden');
        }

        const newPosition = position * 100;

        this.container.style.transform = `translate3d(0, -${newPosition}vh, 0)`;
        this.currentView = view;

        currentView.classList.add('hidden');
        nextView.classList.remove('hidden');

        setTimeout(() => {
            const input = nextView.querySelector('form input:first-of-type');

            if (input) {
                input.focus();
            }
        }, 100);
    },
    onMachineStateChange(state) {
        if (state in this.views) {
            this.transitionTo(state);
        }
    },
    goBack() {
        machine.dispatch('back');
    },
};
