export default class EventManager {
    subscribers = {};

    on(event, cb) {
        if (!(event in this.subscribers)) {
            this.subscribers[event] = [];
        }

        if (!this.subscribers[event].includes(cb)) {
            this.subscribers[event].push(cb);
        }

        return {
            unsubscribe: () => {
                this.subscribers[event] = this.subscribers[event].filter(
                    (subscribedCb) => subscribedCb !== cb,
                );
            },
        };
    }

    emit(event, ...args) {
        if (!(event in this.subscribers)) {
            return;
        }

        this.subscribers[event].forEach(function (cb) {
            cb.call(null, ...args);
        });
    }
}
