import EventManager from './lib/EventManager.js';

export default {
    endpoint: process.env.API_URL,
    onlineInterval: 30000,
    offlineInterval: 5000,
    isOffline: false,
    eventManager: new EventManager(),
    timer: null,
    element: document.getElementById('offline'),
    getInterval() {
        return this.isOffline ? this.offlineInterval : this.onlineInterval;
    },
    start() {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => this.check(), this.getInterval());
    },
    check() {
        fetch(this.endpoint, { method: 'HEAD' })
            .then((res) => {
                if (res.ok) {
                    this.setOffline(false);
                } else {
                    this.setOffline(true);
                }
            })
            .catch(() => {
                this.setOffline(true);
            });
    },
    setOffline(offline = true) {
        this.isOffline = offline;
        this.eventManager.emit(offline ? 'offline' : 'online');

        if (this.element) {
            if (offline) {
                this.element.classList.add('visible');
            } else {
                this.element.classList.remove('visible');
            }
        }

        this.start();
    },
    onOnline(cb) {
        this.eventManager.on('online', cb);
    },
    onOffline(cb) {
        this.eventManager.on('offline', cb);
    },
};
