import EventManager from './EventManager.js';

export default class Machine extends EventManager {
    constructor(states, store = {}, initialState = 'IDLE') {
        super();

        this.states = states;
        this.store = store;
        this.state = initialState;
        this.initialState = initialState;
        this.initialStore = store;
    }

    can(action) {
        const state = this.states[this.state];

        if (state.final) {
            return false;
        }

        return action in state.on;
    }

    onTransition(prevState, input = null) {
        this.emit(`${prevState}-OFF`);
        this.emit(this.state, input);
        this.emit('transition', this.state, prevState, input);
    }

    dispatch(actionName, input = null) {
        if (!this.can(actionName)) {
            return;
        }

        const currentState = this.state;
        this.state = this.states[this.state].on[actionName];

        this.onTransition(currentState, this.state, input);
    }

    start(input = null) {
        this.onTransition(null, input);
    }

    reset(input = null) {
        this.state = this.initialState;
        this.store = this.initialStore;

        this.start(input);
    }

    on(state, cb) {
        return super.on(state, cb);
    }

    off(state, cb) {
        return super.on(`${state}-OFF`, cb);
    }

    isFinal() {
        return this.states[this.state].final;
    }
}
